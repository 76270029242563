<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <div class="chart-container">
      <div class="canvas" id="lineChart"></div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue'
// import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import Filter from '@/components/table/filter'
import { request } from '@/utils/request'
import { common } from '@/common/mixin'
import { getUrlParam } from '@/common/utils'
// import * as echarts from "echarts";
import * as echarts from 'echarts/core'
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'

export default {
  components: {
    Filter
  },
  mixins: [common],
  setup () {
    echarts.use([
      DatasetComponent,
      TitleComponent,
      TooltipComponent,
      DataZoomComponent,
      LegendComponent,
      ToolboxComponent,
      GridComponent,
      CanvasRenderer,
      LineChart
    ])
    const robotId = getUrlParam(window.location.href, 'robotSn')
    let lineChart = null // echarts 实例
    let healthScoreTrendData = []
    const state = reactive({
      query: {
        robotSN: robotId, // Zu3100161
        startTime: '',
        endTime: ''
      },
      params: [
        {
          type: 'Input',
          key: 'robotSN',
          value: robotId,
          label: '机器人编码'
        },
        {
          type: 'Date',
          key: 'dateRange',
          value: [],
          label: '更新日期',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始时间',
            'end-placeholder': '结束时间'
            // "disabled-date": disabledDate
          }
        }
      ]
    })
    const getHealthScoreTrendData = async () => {
      try {
        const res = await request('getHealthScoreTrend', {
          ...state.query
        })
        healthScoreTrendData = res || []
        if (healthScoreTrendData.length <= 0) return
        lineChart.resize()
        lineChart.setOption({
          title: {
            text: '健康评分'
          },
          xAxis: {
            min: healthScoreTrendData[0].createTime || ''
          },
          dataset: {
            source: healthScoreTrendData
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async data => {
      state.query = data
      if (!state.query.robotSN) {
        ElMessage({
          showClose: true,
          type: 'warning',
          message: '机器人编码不能为空'
        })
        return
      }
      if (data.dateRange && data.dateRange.length > 0) {
        state.query.startTime = data.dateRange[0]
        state.query.endTime = data.dateRange[1]
      } else {
        state.query.startTime = ''
        state.query.endTime = ''
      }
      getHealthScoreTrendData()
    }
    onMounted(() => {
      if(robotId) getHealthScoreTrendData()
      lineChart = echarts.init(document.getElementById('lineChart'))
      const lineOption = {
        title: {
          text: '健康评分'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['健康评分']
        },
        grid: {
          left: '2%',
          right: '0%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataset: {
          dimensions: ['createTime', 'score'],
          source: healthScoreTrendData
        },
        xAxis: {
          type: 'time',
          // min: "2022-01-01 00:00:00",
          splitLine: {
            show: false
          },
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          // min: 'dataMin',
          // max: 'dataMax',
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        dataZoom: [
          // {
          //   type: 'inside',
          //   start: 0,
          //   end: 100
          // },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: '健康评分',
            type: 'line',
            smooth: true,
            encode: { x: 'createTime', y: 'score' },
            showSymbol: false,
            sampling: 'average'
          }
        ],
        animation: false
      }
      lineChart.setOption(lineOption)
    })
    onUnmounted(() => {
      lineChart && lineChart.dispose()
    })
    return {
      ...toRefs(state),
      searchData,
      getHealthScoreTrendData,
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  position: relative;
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.chart-container {
  position: relative;
  margin-top: 10px;
  padding: 10px 10px;
  flex: 1;
  // overflow: hidden;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  .canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
